<template>
  <div class="page page-evenemential-exports">
    <div class="container">
      <section class="section-container next-lottery">
        <h2 class="title">
          {{ $t('evenementialExports.head.title') }}
        </h2>

        <p class="subtitle">
          {{ $t('evenementialExports.head.subtitle') }}
        </p>

        <div class="search-container">
          <div class="left">
            <p class="select-period">{{ $t('evenementialExports.search.select') }}</p>
          </div>

          <div class="right">
            <p class="period">{{ $t('evenementialExports.search.period') }}</p>

            <DateRangeExport class="daterange" @dates="dates => selectedDates = dates" />

            <el-button type="danger" class="cta" @click="submit" :disabled="!hasSelectedDates">
              {{ $t('evenementialExports.search.cta') }}
            </el-button>
          </div>
        </div>

        <div class="table-container">
          <TableExport :is-loading="isLoading" :exported="exported" />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { frDateToStdDate } from '@/data/input-rules'
import AutoReload from '@/mixins/AutoReload'

import DateRangeExport from '@/components/export/DateRangeExport'
import TableExport from '@/components/export/TableExport'

export default {
  name: 'EvenementialExports',

  components: {
    DateRangeExport,
    TableExport
  },

  mixins: [AutoReload],

  data () {
    return {
      isLoading: false,
      selectedDates: null,
      exported: [],
      category: 'EVENT_EXPORT'
    }
  },

  computed: {
    hasSelectedDates () {
      return this.selectedDates && this.selectedDates[0] && this.selectedDates[1]
    }
  },

  created () {
    this.update()
  },

  methods: {
    onReload () {
      this.update()
    },

    update () {
      this.isLoading = true

      this.$store.dispatch('exports/getMediaList', { category: this.category })
        .then((medias) => {
          this.exported = medias
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    submit () {
      this.isLoading = true

      const [from, to] = this.selectedDates

      this.$store.dispatch('exports/claimMedia', {
        startDate: frDateToStdDate(from),
        endDate: frDateToStdDate(to),
        category: this.category
      })
        .then(() => this.$message({
          message: `La demande d'export a bien été prise en compte.`,
          type: 'success'
        }))
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title,
.subtitle {
  text-align: center;
}

.subtitle {
  margin: 0 0 48px;
}

.search-container {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;

  .left {
    display: flex;
    align-items: center;
  }

  .right {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
  }

  .period {
    margin: 0 24px 0 0;
  }

  .daterange {
    margin: 0 32px 0 0;
  }
}

.table-container {
  z-index: 0;
  margin: 24px 0 0;
}
</style>
