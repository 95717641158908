export default {
  name: 'AutoReload',

  data () {
    return {
      autoReloadEnabled: true,
      autoReloadTime: 1000 * 60 * 5 // 1000 * 60 * 5 ms = 5mn
    }
  },

  watch: {
    autoReloadEnabled: {
      handler: 'refreshAutoReload',
      immediate: true
    },
    autoReloadTime: {
      handler: 'refreshAutoReload',
      immediate: true
    }
  },

  destroyed () {
    this.removeAutoReload()
  },

  methods: {
    refreshAutoReload () {
      if (this.autoReloadEnabled) {
        this.addAutoReload()
      } else {
        this.removeAutoReload()
      }
    },

    addAutoReload () {
      this.removeAutoReload()
      const onReload = () => {
        this.onReload()
        this.refreshAutoReload()
      }
      this._autoReloadTimeout = setTimeout(onReload, this.autoReloadTime)
    },

    removeAutoReload () {
      clearTimeout(this._autoReloadTimeout)
    },

    onReload () {
      console.warn('Please override the "onReload" method if you use AutoReload mixin')
    }
  }
}
