<template>
  <div>
    <el-table v-loading="isLoading || isDownloading" :data="part" :empty-text="$t('evenementialExports.table.empty')" @sort-change="onSort">

      <el-table-column width="100px" prop="date" sortable="custom" :label="$t('evenementialExports.table.columns.date')">
        <template slot-scope="scope">
          {{ formatDate(scope.row.date) }}
        </template>
      </el-table-column>

      <el-table-column v-if="displayCategory" width="160px" prop="category" sortable="custom" label="Type de données">
        <template slot-scope="scope">
          {{ scope.row.categoryLabel || scope.row.category }}
        </template>
      </el-table-column>

      <el-table-column prop="file" :label="$t('evenementialExports.table.columns.filename')">
        <template slot-scope="scope">
          <span class="filename">
            {{ scope.row.file.name }}
          </span>
        </template>
      </el-table-column>

      <el-table-column width="150px" align="center" prop="file" :label="$t('evenementialExports.table.columns.action')">
        <template slot-scope="scope">
          <el-button type="danger" size="mini" class="cta" @click="download(scope.row)">
            <i class="el-icon-download file-icon"></i>
            {{ $t('evenementialExports.table.downloadCTA') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout="prev, pager, next"
      :current-page="currentPage"
      :total="exported.length"
      :page-size="itemsDisplayCount"
      class="pagination"
      v-if="!(isLoading || isDownloading)"
      hide-on-single-page
      @current-change="num => currentPage = num"
      @prev-click="() => currentPage--"
      @next-click="() => currentPage++"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  name: 'TableExports',

  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    exported: {
      type: Array,
      default () { return [] }
    },
    displayCategory: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isDownloading: false,
      selectedDates: null,
      orderAsc: true,
      orderProp: 'date',
      currentPage: 1,
      itemsDisplayCount: 10
    }
  },

  watch: {
    isLoading (isLoading) {
      if (isLoading) {
        this.currentPage = 1
      }
    },
    currentPage (current) {
      const pageCount = Math.ceil(this.exported.length / this.itemsDisplayCount)
      this.currentPage = Math.max(Math.min(current, pageCount), 1)
    }
  },

  computed: {
    part () {
      const min = (this.currentPage - 1) * this.itemsDisplayCount
      const max = min + this.itemsDisplayCount
      return this.sortedTable.slice(min, max)
    },
    sortedTable () {
      const list = [...this.exported]
      const order = this.orderAsc ? 1 : -1
      const dateSort = (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      const categorySort = (a, b) => (a.categoryLabel || a.category).localeCompare((b.categoryLabel || b.category), 'fr', { ignorePunctuation: true })

      if (this.orderProp === 'date') {
        list.sort((a, b) => dateSort(a, b) * order)
      } else if (this.orderProp === 'category') {
        list.sort((a, b) => categorySort(a, b) * order || dateSort(a, b))
      }

      return list
    }
  },

  methods: {
    onSort ({ prop, order = null }) {
      if (order === 'ascending' || order === null) {
        this.orderAsc = true
      } else if (order === 'descending') {
        this.orderAsc = false
      }
      this.orderProp = prop
    },

    download (row) {
      const options = {
        category: row.category,
        file: row.file
      }

      this.isDownloading = true

      this.$store.dispatch('file/downloadFile', options)
        .then(() => this.$message({
          message: `Le fichier ${row.file.name} a bien été téléchargé`,
          type: 'success'
        }))
        .catch(error => this.$message.error(this.$t(error.message)))
        .finally(() => {
          this.isDownloading = false
        })
    },

    formatDate (date) {
      return new Date(date).toLocaleDateString('fr-FR', {
        year: '2-digit',
        month: 'numeric',
        day: 'numeric'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table ::v-deep .el-table__header thead tr th:nth-child(3) .cell {
  padding-right: 78px;
}
.filename {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pagination {
  margin: 20px 0 0;
  text-align: center;
}
</style>
