<template>
  <div class="date-range-container">
    <el-date-picker
      ref="datePicker"
      type="daterange"
      class="daterange"
      v-model="selectedDates"
      format="dd/MM/yyyy"
      value-format="dd/MM/yyyy"
      :range-separator="$t('evenementialExports.search.rangeSeparator')"
      :start-placeholder="$t('evenementialExports.search.startDatePlaceholder')"
      :end-placeholder="$t('evenementialExports.search.endDatePlaceholder')"
      :picker-options="datePickerOptions"
      @focus="datepickerFocused = true"
      @blur="datepickerFocused = false"
    />

    <div
      class="datepicker-quick-ranges-container"
      :class="{ active: datepickerFocused }"
      :style="datePickerPos"
    >
      <ul>
        <li @click="selectLastWeek">{{ $t('evenementialExports.search.ranges.lastWeek') }}</li>
        <li @click="selectLastMonth">{{ $t('evenementialExports.search.ranges.lastMonth') }}</li>
        <li @click="selectLastThreeMonths">{{ $t('evenementialExports.search.ranges.lastThreeMonths') }}</li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'DateRangeExport',

  data () {
    return {
      datepickerFocused: false,
      selectedDates: null,
      datePickerPos () { return { } },
      datePickerOptions: {
        disabledDate (date) {
          const startDay = new Date('2019-07-11') // 11 juillet 2019 - start transaction date
          startDay.setDate(startDay.getDate() - 1)

          const toDay = new Date()
          toDay.setDate(toDay.getDate() - 1)

          return date.getTime() < startDay.getTime() || date.getTime() > toDay.getTime()
        }
      }
    }
  },

  watch: {
    selectedDates: {
      immediate: true,
      handler (dates) {
        this.$emit('dates', dates)
      }
    }
  },

  mounted () {
    // Sorry for that but resize, scroll etc change the date picker position
    this._raf = requestAnimationFrame(this.onDatePickerPosUpdated)
  },

  destroyed () {
    cancelAnimationFrame(this._raf)
  },

  methods: {
    // Force datepicker-quick-ranges-container to follow datepicker
    onDatePickerPosUpdated () {
      const isDatePickerDisplayed = !!(this.datepickerFocused && this.$refs.datePicker && this.$refs.datePicker.picker && this.$refs.datePicker.picker.$el)

      if (isDatePickerDisplayed) {
        const { top, left } = this.$refs.datePicker.picker.$el.getBoundingClientRect()
        this.datePickerPos = {
          top: top + 'px',
          left: left + 'px'
        }
      }

      cancelAnimationFrame(this._raf)
      this._raf = requestAnimationFrame(this.onDatePickerPosUpdated)
    },

    selectLastWeek () {
      let oneWeekAgo = new Date()
      oneWeekAgo = new Date(oneWeekAgo.setDate(oneWeekAgo.getDate() - 7))

      this.selectedDates = [
        oneWeekAgo.toLocaleDateString('fr-FR'),
        new Date().toLocaleDateString('fr-FR')
      ]
    },

    selectLastMonth () {
      let oneMonthAge = new Date()
      oneMonthAge = new Date(oneMonthAge.setMonth(oneMonthAge.getMonth() - 1))

      this.selectedDates = [
        oneMonthAge.toLocaleDateString('fr-FR'),
        new Date().toLocaleDateString('fr-FR')
      ]
    },

    selectLastThreeMonths () {
      let threeMonthsAgo = new Date()
      threeMonthsAgo = new Date(threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3))

      this.selectedDates = [
        threeMonthsAgo.toLocaleDateString('fr-FR'),
        new Date().toLocaleDateString('fr-FR')
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

.date-range-container {
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
}

.datepicker-quick-ranges-container {
  z-index: 2000;
  position: fixed;
  // left: 0;
  // top: 52px;
  width: 190px;
  background-color: #fff;
  border: 1px solid #E4E7ED;
  border-right: 0;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  pointer-events: none;
  transition: transform 0.15s ease-in-out;
  transform-origin: top;
  transform: translateX(-100%) scaleY(0);

  &.active {
    pointer-events: all;
    opacity: 1;
    transform: translateX(-100%) scaleY(1);
  }

  ul {
    padding: 16px 0;

    li {
      padding: 8px 16px;
      font-size: 16px;
      cursor: pointer;
      background-color: #fff;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
}

</style>
